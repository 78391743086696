// Copied either from:
//   - entry.client.hydrateRoot.jsx
//   - entry.client.reactDOMHydrate.jsx

// There's 2 parts to being able to use fancy React 18 Suspense support (with remix's Await) feature:
//
//   1. Updating entry.client.jsx to use hydrateRoot instead of ReactDOM.hydrate (React 18)
//   2. Updating entry.server.jsx to use renderToPipeableStream (Suspense)
//
// #1 can be done alone, but #2 requires #1 to be done first.

// The main problem with enabling #1 today is that there's TONs of hydration issues
// reported in Sentry, which lead to a blank page, breaking the UI for users.

// When React 19 is released, we'll get proper hydration error messages,
// Allowing us to debug the source of the hydration issues and fix them.

import * as Sentry from "@sentry/remix";

import { StrictMode, useEffect, useState, useCallback } from "react";
import { hydrate as DEPRECATED_REACT_17_HYDRATE } from "react-dom";
import createEmotionCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";

import ClientStyleContext from "./clientStyleContext";

if (window.env && window.env.APP_ENV !== "test") {
  Sentry.init({
    dsn: window.env.SENTRY_DSN,
    environment: window.env.APP_ENV,
    release: window.env.GIT_COMMIT_SHA || "development",
    sampleRate: 1.0,
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    // make sure nginx 'rewrites' uses the same DSN
    tunnel:
      window.env.APP_ENV === "production" || window.env.APP_ENV === "preview"
        ? "/stats/api/sentry"
        : undefined,
    initialScope: {
      user: window.user || {},
    },
    integrations: [
      Sentry.httpClientIntegration(),
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    ],
  });
}

function ClientCacheProvider({ children }) {
  const [cache, setCache] = useState(createEmotionCache({ key: "css" }));

  const reset = useCallback(() => {
    setCache(createEmotionCache({ key: "css" }));
  }, []);

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

const hydrate = () => {
  DEPRECATED_REACT_17_HYDRATE(
    <StrictMode>
      <ClientCacheProvider>
        <RemixBrowser />
      </ClientCacheProvider>
    </StrictMode>,
    document,
  );
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/entry.worker.js")
    .then(() => navigator.serviceWorker.ready)
    .then(() => {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "SYNC_REMIX_MANIFEST",
          manifest: window.__remixManifest,
        });
      } else {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          navigator.serviceWorker.controller?.postMessage({
            type: "SYNC_REMIX_MANIFEST",
            manifest: window.__remixManifest,
          });
        });
      }
    })
    .catch((error) => {
      console.error("Service worker registration failed", error);
    });
}
